.navbarListIcon {
  display: none;
  column-gap: 1.6rem;
  align-items: baseline;
  position: relative;
}

.navbarListIcon svg {
  cursor: pointer;
  width: 1.125rem;
  height: 1.25rem;
  fill: black;
}

.navbarListIcon .carIcon {
  width: 4.29rem;
}

.navbarListIcon .smallListModal {
  position: absolute;
  width: 9.838rem;
  height: auto;
  padding: 1rem 0;
  /* height: 12.5rem; */
  background-color: #ffffff;
  top: 2rem;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-user-select: none;
  user-select: none;
  z-index: 60;
}

.navbarListIcon .smallListModal ul {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 65%;
  row-gap: 0.938rem;
}

.navbarListIcon .smallListModal ul li,
.navbarListIcon .smallListModal ul li a {
  color: #9a9ea7;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.navbarListIcon .smallListModal img {
  position: absolute;
  top: 1.063rem;
  right: 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
}

@media (min-width: 868px) {
  .navbarListIcon {
    display: flex;
  }
}