.resetForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
  }

  .resetForm .icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1.875rem;
    margin-bottom: 2.625rem;
  }
  
  .resetForm p {
    display: flex;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.10000000149011612px;
    text-align: left;
    align-items: center;
    color: #9a9ea7;
  }
  
  .resetForm p input {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.625rem;
  }
  
  .signinOptions {
    display: flex;
    justify-content: space-between;
  }
  
  .recoverPassword {
    color: #7963f0;
    font-weight: 600;
    font-size: 0.875rem;
    cursor: pointer;
  }
  
  .sumbitButton {
    height: 2.875rem;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 0.625rem;
    margin-bottom: 1rem;
  }
  