.auctionsContainer {
    width: 90%;
    margin: auto;
    margin-top: 6.125rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.438rem;
    min-height: 31.75rem;
  }
  
  .auctionsContainer .header {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
  
  .auctionsContainer .header .btns {
    display: none;
    flex-direction: column;
    align-items: center;
    row-gap: 1.438rem;
  }
  
  .auctionsContainer .header .btns a {
    font-weight: 700;
    font-size: 1.125rem;
  }
  
  .auctionsContainer .header .btns div {
    display: flex;
    column-gap: 1.563rem;
  }
  
  .auctionList {
    width: 95%;
    height: 500px;
    margin: auto;
  }
  
  .sliderOuter {
    width: 85vw;
    margin-left: auto;
    margin-right: auto;
  }
  
  .prevImage {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-left: -20px;
  }
  
  .nextImage {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-left: 18px;
    transform: scale(-1);
  }
  
  @media (max-width: 1600px) {
    .sliderOuter {
      width: 95vw;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  @media (min-width: 868px) {
    .auctionsContainer {
      width: 95%;
    }
    .auctionsContainer .header {
      width: 100%;
      margin: 0;
    }
    .auctionsContainer .header .btns {
      display: flex;
    }
  }
  
  @media (max-width: 640px) {
    .prevImage {
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-left: 0px;
      margin-top: -50px;
      position: absolute;
    }
  
    .nextImage {
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-left: -27px;
      transform: scale(-1);
      position: absolute;
      margin-top: -50px;
    }
  }
  