.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.563rem;
  padding: 0 1rem;
  border-bottom: 1px solid #dee2e6;
}

.header h4 {
  font-size: 12px;
  margin: 0 auto;
  font-weight: 700;
}

.header button {
  border: none;
  background: transparent;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.header button:hover {
  opacity: 0.75;
}



@media (min-width: 868px) {
  .header h4 {
    font-size: 1.125rem;
  }
}