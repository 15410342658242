.modal {
  width: 100%;
  height: 100%;
}

.signupDetailsMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.signupDetailsBody {
  width: 28.125rem;
  height: auto;
  padding: 2rem;
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  margin: 0 auto;
}

@media (min-width: 868px) {
  .modal {
    width: 28.125rem;
    min-height: 34rem;
    height: auto;
  }

  .signupDetailsBody {
    margin: 0;
  }
}
