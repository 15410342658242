.signupForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.sumbitButton {
  height: 3.188rem;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.625rem;
}
