.modal {
  width: 30rem;
  height: auto;
  align-items: center;
  border-radius: 0.875rem;
  padding-bottom: 50px;
}

.modalOuterContainer {
  overflow-y: auto;
  max-height: 28rem !important;
}

.modal .header {
  justify-content: space-between;
  border-bottom: none;
  padding: 2rem 2rem;
  font-weight: 600;
  border-top-left-radius: 0.875rem;
  border-top-right-radius: 0.875rem;
  background: white;
}

.modalOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalOuter img {
  width: 262px;
  height: 48px;
}

.auctionName {
  color: black;
  font-size: 19px;
  font-family: Inter;
  font-weight: 600;
  line-height: 19.46px;
  margin-top: 16px;
  margin-bottom: 6px;
}

.auctionNo {
  color: black;
  font-size: 19px;
  font-family: Inter;
  font-weight: 400;
  line-height: 19.46px;
  margin-bottom: 24px;
}


.tokenCircle {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: #f4b720;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin-bottom: 32px;
}

.tokenCircle h1 {
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  line-height: 21px;
  margin-top: 21px;
}

.tokenCircle h4 {
  color: white;
  font-size: 120px;
  font-family: Inter;
  font-weight: 700;
  line-height: 21px;
  margin-top: 46px;
}

.dateDiv {
  display: flex;
  margin-bottom: 13px;
}


@media (max-width: 1020px) {
  .modalOuter img {
    width: 206px;
    height: 37px;
  }

  .auctionName {
    font-size: 14px;
  }

  .auctionNo {
    font-size: 14px;
  }

  .participantTitle {
    font-size: 14px;
  }

  .participantName {
    font-size: 14px;
  }

  .tokenCircle {

    width: 144px;
    height: 144px;

  }

  .tokenCircle h1 {
    margin-top: 16px;
  }

  .tokenCircle h4 {

    font-size: 98px;
    margin-top: 39px;
  }


  .dateDiv h3 {

    font-size: 14px;

  }

  .dateDiv h4 {

    font-size: 14px;

  }
}
