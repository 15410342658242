.arrowButton {
  /* width: 1.875rem; */
  width: fit-content;
  height: 1.875rem;
  border: solid #bdbdbd 1px;
  border-radius: 0.375rem;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  /* font-size: 0.938rem; */
  font-family: "Inter", sans-serif;
  padding: 0 1rem;
}

.arrowButton .arrow {
  border: solid #9a9ea7;
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 0.65rem;
  height: 0.65rem;
  margin: auto;
}

.arrowButton .arrow.left {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.arrowButton .arrow.right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}