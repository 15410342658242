.inputContainer {
  position: relative;
}

.inputContainer input,
.inputContainer select {
  border: 1px solid #cecece;
  color: #333;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.875rem 2.188rem 0 1rem;
  width: 100%;
  height: 4.375rem;
  border-radius: 0.5rem;
}

/* Chrome, Safari, Edge, Opera */
.inputContainer input::-webkit-outer-spin-button,
.inputContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputContainer input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

.inputContainer label {
  color: #9e9e9e;
  font-size: 0.875rem;
  font-weight: 400;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1rem;
  pointer-events: none;
  margin-bottom: 0;
  transition: all 0.2s ease-in-out;
}

.inputContainer select + label {
  transform: translateY(-1.7rem);
}

.inputContainer input:focus + label,
.inputContainer input:not(:placeholder-shown) + label {
  transform: translateY(-1.7rem);
}

.inputContainer .icon {
  position: absolute;
  right: 1.2rem;
  top: 1.7rem;
  z-index: 1;
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
}
