.modal {
    width: 30rem;
    height: 23rem;
    align-items: center;
    border-radius: 0.875rem;
    
}

.modalOuterContainer{
  overflow-y: auto;
  max-height: 28rem !important;

}

.modal .header {
    justify-content: space-between;
    border-bottom: none;
    padding: 2rem 2rem;
    font-weight: 600;
    border-top-left-radius: 0.875rem;
    border-top-right-radius: 0.875rem;
    background: white;
}


.modalOuter{
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: -50px;
}

.modalOuter h4{
    color: #FF0000;
font-size: 20px;
font-family: Inter;
font-weight: 500;
line-height: 20.11px;
text-align: center;
}

.modalOuter h2{
    font-size: 20px;
    font-family: Inter;
font-weight: 500;
line-height: 20.11px;
text-align: center;
margin-top: -20px;
margin-bottom: 25px;
}

.modalOuter button{
    width: 184px;
    height: 44px;
    background: #F2AD00;
    border-radius: 8px;
    border: none;
    margin-top: 30px;
}

.modalOuter h1{
    color: black;
font-size: 18px;
font-family: Inter;
font-weight: 700;
line-height: 21px;
}

.modalOuter span{
    color: white;
font-size: 18px;
font-family: Inter;
font-weight: 700;
line-height: 21px;
}

@media (max-width: 1020px){
    .modalOuter h4{
        color: #FF0000;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 20.11px;
    text-align: center;
    }
}