.modal {
    width: 40rem;
    height: 22.5rem;
    align-items: center;
    border-radius: 0.875rem;
}

.modalOuterContainer{
  overflow-y: auto;
  max-height: 28rem !important;
  
}

.modal .header {
    justify-content: space-between;
    border-bottom: none;
    padding: 2rem 2rem;
    font-weight: 600;
    border-top-left-radius: 0.875rem;
    border-top-right-radius: 0.875rem;
    background: #D8D8D8;
}

.outerWrapper{
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    height: 18rem;
    align-items: center;
    justify-content: center;
}

.outerWrapper input{
    width: 300px;
    height: 50px;
    border-radius: 8px;
    font-size: 18px;
    padding: 14px;
    border: 1px #DFDFDF solid;
    
}

.outerWrapper button{
    width: 200px;
    padding: 12px 10px;
}
