.modal {
  width: 544px;
  height: auto;
  align-items: center;
  border-radius: 0.875rem;
  padding-bottom: 30px;
}

.modalOuterContainer {
  overflow-y: auto;
  max-height: 28rem !important;
}

.modal .header {
  justify-content: space-between;
  border-bottom: none;
  padding: 2rem 2rem;
  font-weight: 600;
  border-top-left-radius: 0.875rem;
  border-top-right-radius: 0.875rem;
  background: white;
}

.modalOuter {
  display: flex;


  padding-left: 38px;
  padding-right: 37px;
}

.modalOuter img{
  
}

.auctionTitle{
  display: flex;
  margin-top: 14px;
}

.auctionTitle h1{
  color: black;
font-size: 16px;
font-family: Inter;
font-weight: 600;
line-height: 22.24px;
}

.auctionTitle h2{
  color: black;
font-size: 16px;
font-family: Inter;
font-weight: 400;
line-height: 22.24px;
}

.imageContainer{
  width: 230px;
  border-bottom: 1px #E2E2E2 solid;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
}

.tokenContainer{
  display: flex;
  flex-direction: column;

}

.rightDiv{
  width: 239px;
  height: 263px;
  background-color: #F3B720;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  
}

.tokenContainer{
  height: 60%;
  padding-top: 20px;
  border-bottom: 1px #F4F3F3 dotted;
  width: 100%;
  text-align: center;

}

.nameSection{
  height: 40%;
  width: 100%;
  text-align: center;
}

.tokenContainer h2{
  color: black;
font-size: 18.08px;
font-family: Inter;
font-weight: 700;
line-height: 27.12px;
margin-bottom: 35px;
}

.tokenContainer h3{
  color: black;
font-size: 80px;
font-family: Inter;
font-weight: 800;
line-height: 27.12px;
height: auto;
}

.nameSection h2{
  color: black;
font-size: 16px;
font-family: Inter;
font-weight: 600;
line-height: 22.24px;
margin-top: 20px;
}

.nameSection h3{
  color: black;
font-size: 16px;
font-family: Inter;
font-weight: 400;
line-height: 22.24px;
}

@media (max-width: 1020px){
  .modalOuter {
    flex-direction: column;
    gap: 10px;
  }

  .modal {
    width: 90vw;
    height: auto;
    align-items: center;
    border-radius: 0.875rem;
    padding-bottom: 30px;
  }
}

