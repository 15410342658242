.footer {
  width: 100%;
  height: 17.25rem;
  /* margin-top: 10rem; */
  background-color: #f5f6f7;
  display: flex;
}

.footerContent {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.footerBottom{
  width: 100%;
  padding-top: 18px;
  padding-bottom: 17px;
  padding-left: 100px;
  padding-right: 100px;
  background: #444444;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
font-size: 16px;
font-family: inter;
font-weight: 500;
line-height: 26px;
text-align: center;

}


@media (min-width: 868px) {
  .footerContent {
    width: 80%;
  }
}
