html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Inter', sans-serif;
  color: #0d0a19;
  list-style: none;
  background-color: #fafafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  font-size: 10px;
}

@media (min-width: 640px) {
  :root {
    font-size: 12px;
  }
}

@media (min-width: 868px) {
  :root {
    font-size: 16px;
  }
}

/* @media (min-width: 1024px) {
  :root {
    font-size: 20px;
  }
} */

/* @media (min-width: 1440px) {
  :root {
    font-size: 16px;
  }
} */

@media (min-width: 1920px) {
  :root {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  :root {
    font-size: 30px;
  }
}

/* react-image-gallery - making thumbnail image responsive */
.image-fit img {
  object-fit: scale-down !important;
}

.invoiceTable .ant-table-tbody>tr>td {
  /* remove bottom border of each row */
  border-bottom: 0 !important;
  /* to decrease height of row */
  padding: 2.5224mm 16px !important;
}

.invoiceTable .ant-table-thead .ant-table-cell {
  /* remove bottom border of heading row */
  border-bottom: 0 !important;
}

.invoiceTable .ant-table-thead .ant-table-cell::before {
  /* remove vertical lines b/w column headings */
  height: 0 !important;
}

.invoiceTable .ant-table {
  /* remove white background of rows */
  background: transparent !important;
}

.invoiceTableHigh .ant-table-tbody>tr>td {
  /* remove bottom border of each row */
  border-bottom: 0 !important;
  /* to decrease height of row */
  padding: 16px !important;
}

.invoiceTableHigh .ant-table-thead .ant-table-cell {
  /* remove bottom border of heading row */
  border-bottom: 0 !important;
}

.invoiceTableHigh .ant-table-thead .ant-table-cell::before {
  /* remove vertical lines b/w column headings */
  height: 0 !important;
}

.invoiceTableHigh .ant-table {
  /* remove white background of rows */
  background: transparent !important;
}

.ant-picker-outlined {
  /* background: #ffffff; */
  /* border-width: 1px; */
  /* border-color: #d9d9d9; */
  width: 280px !important; 
  border: none !important;
  background: none !important;
  cursor: pointer;
}

.ant-picker-input {
  cursor: pointer !important;
  font-size: 14px !important;
}


.ant-picker-input > input::placeholder {
  color: #808080 !important;
  font-size: 14px;
  font-family: inter !important;
  font-weight: 700;
 
}

.invoice-filter-form{
  display: flex;
}

.ant-form-item {
  margin-bottom: 0px;
}
