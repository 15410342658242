.navbar {
  width: 100%;
  height: 4.688rem;
  background-color: white;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin-bottom: 3rem;
  overflow-y: auto;
}

.navbarItem {
  padding: 0.625rem 1.25rem;
  white-space: nowrap;
  margin-right: 20px;
}

.navbarItem a {
  font-weight: 700;
  font-size: 0.875rem;
  color: #9a9ea7;
}

.navbarItem a.active {
  color: #0d0a19;
  border: solid black 1px;
  border-radius: 0.5rem;
  border-color: #eeeeee;
  background-color: white;
  padding: 0.625rem 1.25rem;
  min-width: 6rem;
}

@media (min-width: 868px) {
  .navbar {
    /* padding-left: 18%; */
    justify-content: center;
  }
}
