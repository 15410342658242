.button {
  border-radius: 0.375rem;
  border: none;
  padding: 0.563rem 1.25rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
}

.button.colored {
  background: #f2ad00;
  color: #fff;
}

button.colored:disabled {
  background: #ffd97b;
  color: #fff;
}

.button.uncolored {
  background-color: #fff;
  border: 1px solid #9a9ea7;
}

.button.coloredGray {
  background-color: #f5f6f7;
  border-radius: 0.625rem;
  color: #9a9ea7;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0;
  border: none;
}

.button.coloredGreen {
  background-color: #00a541;
  color: white;
}

.button.coloredBlack {
  background-color: #333333;
  color: white;
}

.button:disabled {
  cursor: not-allowed;
}