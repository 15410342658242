.sideNavbar {
  position: fixed;
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 21px;
  margin-top: 5rem;
}

.sideNavbar .searchInput {
  width: 90%;
}

.sideNavbar .searchInput input {
  background-color: #f3f3f3;
  border: none;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.sideNavbar .navbarList {
  flex-direction: column;
  width: 85%;
  font-size: 16px;
  font-weight: 600;
  row-gap: 12px;
}
