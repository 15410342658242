.mainNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 90%;
}

.navbarList {
  display: none;
  align-items: center;
  column-gap: 2rem;
}

.navbarList button {
  white-space: nowrap;
}

.humburgerButton {
  display: inline-block;
}

@media (min-width: 1020px) {
  .navbarList {
    display: flex;
  }
  .humburgerButton {
    display: none;
  }
}
