.modal {
    width: 100%;
    height: 100%;
  }
  
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }
  
  .body {
    width: 28.125rem;
    margin: auto;
    padding: 2rem;
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
  }
  
  .lineBreak {
    text-align: center;
    position: relative;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.313rem;
    color: #9a9ea7;
    width: 100%;
    margin: 0;
  }
  
  .lineBreak::before {
    content: '';
    display: block;
    width: 45%;
    height: 1px;
    background: #ede8e9;
    position: absolute;
    top: 50%;
    left: 0;
  }
  
  .lineBreak::after {
    content: '';
    display: block;
    width: 45%;
    height: 1px;
    background: #ede8e9;
    position: absolute;
    top: 50%;
    right: 0;
  }
  
  .socialBtns {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
  
  .socialBtns button {
    width: 100%;
    height: 3.375rem;
    opacity: 0.5;
    font-size: 0.875rem;
    font-weight: 600;
  }
  
  .socialBtns button svg {
    float: left;
    margin-top: 0.313rem;
    width: 1rem;
    height: 1rem;
  }
  
  .signinFooter {
    display: flex;
    border-top: 1px solid #dee2e6;
    height: 3.563rem;
    font-size: 0.875rem;
  }
  
  .signinFooter p {
    margin: auto;
  }
  
  .signUpLink {
    padding: 0px 10px;
    font-weight: bold;
    cursor: pointer;
  }
  
  @media (min-width: 868px) {
    .modal {
      width: 28.125rem;
      height: auto;
    }
  
    .body {
      margin: 0;
    }
  }
  