.selectInput {
  /* border: solid #e4e6e8 1px; */
  border: none;
  font-size: 14px;
  color: #9a9ea7;
  /* background-color: #ffffff; */
  background-color: #eee;
  padding: 0.625rem 1.125rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

@media (min-width: 868px) {
  .selectInput {
    font-size: 0.875rem;
  }
}
