.notificationBar {
  width: 100%;
  height: 5rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 70;
}

.notificationBar.success {
  background-color: green;
}

.notificationBar.error {
  background-color: red;
}

.notificationBar p {
  margin: auto;
  font-size: 1.2rem;
  font-weight: 700;
  color: black;
}

.notificationBar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.2rem;
  background-color: white;
  animation: load 2s forwards;
}

@keyframes load {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}