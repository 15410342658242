.navListContainer {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}

.navListContainer h4 {
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 7px;
}

.navListContainer li {
  margin-bottom: 7px;
}

.navListContainer .navList {
  display: flex;
  flex-direction: column;
  color: #9a9ea7;
  font-size: 0.875rem;
  font-weight: 400;
}
