.sideFooter {
  width: 100%;
  height: 255px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  background-color: #f5f6f7;
  padding-top: 4rem;
  padding-bottom: 1.5rem;
}

.sideFooter .section1 {
  display: flex;
  flex-direction: column;
  order: 2;
  row-gap: 25px;
  text-align: center;
}

.sideFooter .section1 a {
  font-weight: 700;
}

.sideFooter .section1 p {
  font-weight: 400;
  color: #9a9ea7;
}

.sideFooter .section2 {
  display: flex;
  width: 95%;
  justify-content: space-between;
  font-weight: 700;
  color: #9a9ea7;
}

@media (min-width: 868px) {
  .sideFooter {
    width: 100%;
    height: 3.5rem;
    flex-direction: row;
    font-size: 0.875rem;
    background-color: transparent;
    margin: auto;
  }

  .sideFooter .section1 {
    flex-direction: row;
    order: 1;
    column-gap: 0.875rem;
  }

  .sideFooter .section2 {
    width: -moz-fit-content;
    width: fit-content;
    justify-content: center;
    column-gap: 1.563rem;
    order: 2;
  }
}
