.navbarItem {
  display: inline-block;
  white-space: nowrap;
  margin-right: 30px;
}

.navbarItem a {
  color: #0d0a19;
}

.navbarItem a:hover {
  /* color: #20183d; */
  text-decoration: none;
}

.navbarItem a.active {
  /* text-decoration: underline;
  text-underline-offset: 0.5rem; */
  font-weight: 900;
  color: black;
}
