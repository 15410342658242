.searchInput {
  border: 1px solid #9a9ea7;
  box-shadow: 0px 3px 4px rgb(153 155 168 / 15%);
  border-radius: 0.375rem;
  font-size: 0.875rem;
  width: 16rem;
  padding: 0.625rem 1.25rem;
  -webkit-user-select: none;
  user-select: none;
}

.submitButton {
  display: none;
}
