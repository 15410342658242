.auctionItem {
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;
    width: 259.7px;
    height: 433.11px;
    margin: auto;
  }
  
  .auctionItem img {
    object-fit: cover;
    width: 100%;
    height: 147px;
    border-radius: 8px;
  }
  
  .auctionItem .info {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    text-align: left;
    width: 100%;
  }
  
  .auctionItem .info h1 {
    font-weight: 700;
    font-size: 14px;
  }
  
  .auctionItem .info h4 {
    font-weight: 600;
    font-size: 14px;
  }
  
  .auctionItem .info p {
    font-weight: 600;
    font-size: 12px;
    color: #6c6c6c;
  }
  
  .auctionItem .category {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.75rem;
    /* text-align: left; */
    width: 100%;
  }
  
  .auctionItem .liveCircle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FF0000;
  }
  
  .auctionItem .liveOuterDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
  }
  
  .auctionItem .rightSection{
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  
  .auctionItem .category button {
    width: 77.5px;
    height: 29px;
    border-radius: 5px;
    font-size: 11px;
    background: #36AE54;
    padding: 0px;
  }
  
  .auctionItem .category img {
    width: 33.89px;
    height: 35px;
  }
  
  .auctionItem .btns {
    display: flex;
    column-gap: 1.2rem;
    width: 100%;
  }
  
  .auctionItem .btns button {
    width: 123px;
    height: 33.98px;
    border-radius: 8px;
    font-size: 10px;
  }
  
  @media (min-width: 868px) {
    .auctionItem {
      height: 29.766rem;
      width: 23.75rem;
    }
  
    .auctionItem img {
      height: 13.438rem;
      border-radius: 0.5rem;
    }
  
    .auctionItem .info h1 {
      font-size: 1.25rem;
    }
  
    .auctionItem .info h4 {
      font-size: 1.125rem;
    }
  
    .auctionItem .info p {
      font-size: 1rem;
    }
  
    .auctionItem .btns button {
      width: 10.75rem;
      height: 2.976rem;
      border-radius: 0.5rem;
      font-size: 1rem;
    }
  }
  
  @media (min-width: 1440px) {
    .auctionItem {
      margin: 0;
    }
  }
  